.confirm-dialog.hide {
  display: none;
}

.confirm-dialog.show {
  display: block;
}

.overlay.show {
  z-index: 1234;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #00000069;
}

.confirm-dialog {
  min-width: 175px;
  width: 15%;
  padding: 25px;
  background-color: var(--background-main);
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .confirm-dialog-container {
  display: flex;
  justify-content: center;
} */

.confirm-text {
  font-size: 1rem;
  margin: 20px 0;
}

.confirm-dialog-options {
  white-space: nowrap;
}

.confirm-btn {
  font-size: 0.9rem;
  color: white;
  border-radius: 2px;
  padding: 5px 10px;
  border: none;
  margin-right: 10px;
  cursor: pointer;
  transition: 0.15s;
}

.confirm-yes-btn {
  background-color: var(--primary-main);
}

.confirm-yes-btn:hover {
  transform: scale(1.05);
  background-color: var(--primary-dark);
}

.confirm-no-btn {
  background-color: #0000005e;
}

.confirm-no-btn:hover {
  transform: scale(1.05);
  background-color: rgba(14, 14, 14, 0.493);
}
