.navbar-container {
  display: flex;
}

.navbar {
  margin: 1rem 0 0rem 0;
}

.navbar button {
  border: none;
  background: none;
}

.nav-item {
  font-size: 1rem;
  color: var(--primary-main);
  z-index: 1;
}

.navbar .nav-item {
  margin: 0 1rem;
}
.nav-item .underline {
  width: 100%;
  border-radius: 0.1rem;
  position: relative;
  transition: 0.15s;
}

.nav-item.not-hover .underline {
  height: 3px;
  background: none;
}

.nav-item.hover .underline,
.nav-item.active .underline {
  height: 3px;
  background-color: var(--primary-main);
}

.nav-menu {
  top: 0;
  width: 100%;
  display: none;
}

.nav-menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  background-color: var(--background-main);
  transition: 0.15s;
  z-index: 2;
}

.nav-menu-container.hidden {
  visibility: hidden;
  height: 0;
  opacity: 0;
}
.nav-menu-container.visible {
  opacity: 1;
  position: fixed;
  visibility: visible;
  height: 100vh;
}

.nav-menu .nav-item {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  text-align: center;
  font-size: 1.5rem;
}


#blob1_navmenu,
#blob2_navmenu {
  position: fixed;
  z-index: 3;
  opacity: 0.3;
  width: 10%;
  height: auto;
  overflow: hidden;
  animation: fadeIn 0.3s ease-in;
}

#blob1_navmenu {
  top: 65%;
  left: -40%;
}

#blob2_navmenu {
  top: 5%;
  left: 55%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.3;
  }
}

@media only screen and (max-width: 600px) {
  .nav-menu {
    display: flex;
  }
  .navbar {
    display: none !important;
  }
}

@media only screen and (max-width: 500px) {
  .nav-menu-container.visible {
    margin-top: 51px;
  }
}
