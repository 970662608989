header {
  padding: 1rem;
  font-family: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* top: 0; */
  background-color: var(--background-main);
}

.logo-container {
  width: 100%;
  z-index: 1;
}

.logocontainer-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logocontainer-mobile .logo {
  margin: 0;
}

.logo {
  height: 86px;
  width: auto;
  float: right;
  margin-right: 0.5rem;
}

.wave {
  position: absolute;
  left: 0%;
  top: -2rem;
  z-index: 0;
  width: 100%;
  height: 15rem; /*  Needed to limit the height of wave  */
  opacity: 0.85;
}

.main-title {
  font-family: Comfortaa !important;
  color: var(--primary-main);
  padding-left: 5px;
  z-index: 1;
}

.hamburger-container {
  z-index: 2;
}

.hamburger-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
  width: 36px;
}

.hamburger {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  display: none;
  height: 36px;
  width: 36px;
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

.hamburger span {
  background-color: var(--primary-main);
  border-radius: 2px;
  width: 100%;
  height: 4px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

.hamburger span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.hamburger span:nth-child(2) {
  margin-top: 8px;
  top: 18px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.hamburger span:nth-child(3) {
  top: 36px;
  margin-top: 8px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

.hamburger.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: -3px;
  left: 8px;
}

.hamburger.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 39px;
  left: 8px;
}

@media only screen and (max-width: 600px) {
  header {
    z-index: 3;
    position: fixed;
    width: 100%;
    background-color: var(--background-main);
  }
  
  .main-title {
    padding: 0;
  }

  .hamburger-container {
    margin-right:0.5rem;
  }

  .hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
