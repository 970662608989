.error-refresh-btn {
  font-family: inherit;
  border: none;
  background: none;
  color: var(--link-blue);
  font-size: 0.9rem;
  cursor: pointer;
  padding: 0;
}

.error-page p {
  font-size: 0.9rem;
}

.error-page-error-msg {
  font-family: monospace;
  opacity: 0.7;
}

.error-refresh-btn:hover {
  text-decoration: underline;
}
