.toast {
  opacity: 0.95;
  color: hsla(0, 0%, 100%, 1);
  width: 100%;
  position: fixed;
  transition: 0.2s ease-in-out;
  z-index: 123;
  top: 20px;
}

.toast.hide {
  margin-top: -300px;
}

.toast.show {
  margin-top: 0;
}

.toast-content {
  position: relative;
  display: flex;
  margin: auto;
  width: fit-content;
  max-width: 280px;
  padding: 10px;
  border-radius: 5px;
}

.toast.error .toast-content {
  background-color: var(--danger-light);
}
.toast.warning .toast-content {
  background-color: var(--warning);
}
.toast.success .toast-content {
  background-color: var(--success);
}

.toast-body {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 5px;
}

.toast-content p {
  margin: 0 calc(24px / 2) 0 0;
}

.toast-title {
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 600;
}

.toast-close {
  position: absolute;
  top: 0;
  right: 0;
}

.toast-close img {
  cursor: pointer;
  width: 24px;
  height: 24px;
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.toast-text {
  font-size: 0.95rem;
}

@media only screen and (max-width: 600px) {
  .toast.show {
    margin-top: 45px !important;
  }
}
